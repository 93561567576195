
export const environment = {
  production: true,
  RootDomain: "glamera.com",
  ApiUrl: "https://api-production-dump.glamera.com/api/",
  NewApiUrl: "https://api-production-dump-new.glamera.com/api/",
  ApiUrlV2: "https://api-production-dump.glamera.com/api/v2/",
  UploadUrl: "https://api-production-dump.glamera.com/api/Upload/",
  OldApiUrlFromGlamatekWeb: "https://api.glamour-plan.com/api",
  ReportDevUrl: "https://reports.glamera.com/",
  OnlineReportDevUrl: "https://reports.glamera.com/",
  SurePayUrl: 'https://localhost/Api/Merchant/v1/',
  BillingPortal: "https://subscription.glamera.com/",
  BioTimeSignalRUrl: "",
  SubscriptionAPI: "https://api-dev-new.glamera.com/api/",
  ThemeBuilderApiUrl: "http://localhost:3000/",
  version: '0.0.0',
};


